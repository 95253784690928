.ac-temperature {
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
}

.energy-saving-label {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 10px;
  left: 65px;
  height: 45px;
  width: 50px;
  border-radius: 4px;
  border: 3px solid #4caf50;
}

.label-font {
  display: block;
  width: 330%;
  transform: scale(0.3);
  transform-origin: left top;
  margin: 2px auto;
  margin-left: 2px;
}

.ximalaya-link {
  margin-top: -21px;
  margin-left: 2px;
}

.ximalaya-text-link {
  color: #63a5ef;
  text-decoration: none;
  cursor: pointer;
}

.ximalaya-logo {
  margin: 0;
  padding: 0;
  top: -1rem;
  width: 40px;
}
